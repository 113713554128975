<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />
        {{ $t('reportmember') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          icon
          fab
          outlined
          :disabled="exportLoading"
          :loading="exportLoading"
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        item-key="number"
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.order_id_pri`]="{ index }">
          <span v-if="dataTableList.length != index + 1">
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id_pri"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_create`]="{ item }">
          {{ item.order_create | sumdatetime }}
        </template>
        <template v-slot:[`item.user_fullname`]="{ item }">
          <span v-if="item.user_fullname == $t('total')" class="primary--text font-weight-bold">
            {{ item.user_fullname }}
          </span>
          <span v-else>
            {{ item.user_fullname }}
          </span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span v-if="item.user_fullname == $t('total')" class="primary--text font-weight-bold">
            {{ item.total }}
          </span>
          <span v-else>
            {{ item.total }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Comeback from '../Comeback.vue'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { reportmember, removeComma } from '../useExcel'
import { ref } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import { sumdate, sumdatetime } from '@/plugins/filters'

export default {
  components: {
    Comeback,
    DateFilters,
  },
  filters: {
    sumdate,
    sumdatetime,
  },

  setup() {
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'order_id_pri', width: 50 },
      { text: i18n.t('a_number'), value: 'order_id', width: 80 },
      { text: i18n.t('list'), value: 'detail', width: 250 },
      {
        text: i18n.t('point'),
        value: 'point',
        width: 100,
        align: 'end',
      },
      {
        text: i18n.t('firstname_lastname'),
        value: 'customer_name',
        width: 200,
      },
      { text: i18n.t('date'), value: 'order_create', width: 160 },
      {
        text: i18n.t('user_is_list'),
        value: 'user_fullname',
        width: 200,
      },
      {
        text: i18n.t('price'),
        value: 'total',
        width: 180,
        align: 'end',
      },
    ])
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const header = ref('')
    const payload = ref({})
    const exportLoading = ref(false)

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const { data } = await reportmember(payload)
      dataTableList.value = data
      header.value = `${i18n.t('reportmember')} ${i18n.t('detail_at_day')} ${i18n.t('since')} ${sumdate(
        start,
      )} ${i18n.t('to')} ${sumdate(end)}`

      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${header.value}.xlsx`
      const list1 = JSON.parse(JSON.stringify(dataTableList.value))
      const tableList = list1.map((item, i) => {
        item.number = i + 1

        delete item.order_id_pri
        return item
      })
      const Heading = [
        [header.value],
        [
          '#',
          `${i18n.t('a_number')}`,
          `${i18n.t('list')}`,
          `${i18n.t('point')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('date')}`,
          `${i18n.t('user_is_list')}`,
          `${i18n.t('price')}`,
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, await removeComma(JSON.parse(JSON.stringify(tableList))), {
        header: ['number', 'order_id', 'detail', 'point', 'customer_name', 'order_create', 'user_fullname', 'total'],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }]
      const wscols = [{ wch: 6 }, { wch: 20 }, { wch: 30 }, { wch: 15 }, { wch: 10 }, { wch: 30 }, { wch: 30 }]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('reportmember')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      exportLoading,
      columns,
      dataTableList,
      loading,
      options,
      mdiFileExcelOutline,
      addPayload,
      exportExcel,
    }
  },
}
</script>

<style lang="scss" scoped></style>
